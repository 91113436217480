* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
    background: white;
}

body {

}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box !important;
    font-family: 'Poppins', sans-serif !important;
    line-height: normal;
}

@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}
